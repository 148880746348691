"use client";

import { useEffect } from "react";
import initializeGA from "./google-analytics";

export default function GoogleAnalytics() {
  useEffect(() => {
    const isGAInitialized = (window as any).GA_INITIALIZED;
    if (!isGAInitialized) {
      initializeGA();
      (window as any).GA_INITIALIZED = true;
    }
  }, []);

  return <></>;
}

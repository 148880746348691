import Image from "next/image";
import Link from "next/link";

export default function Logo() {
  return (
    <Link href="/" className="block relative w-24 h-10" aria-label="Cidios">
      <Image className="md:max-w-none mx-auto rounded" src="/logo.png" layout={"fill"} objectFit={"contain"} alt="Cidios" />
    </Link>
  );
}

import "@/css/style.css";
import { LayoutKeys, Layouts } from "@/layout/layouts";
import GoogleAnalytics from "@/utils/GoogleAnalytics";
import { NextComponentType, NextPage, NextPageContext } from "next";
import { AppProps } from "next/app";
import { Inter } from "next/font/google";

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
  display: "swap",
});

export type MyPage<P = {}, IP = P> = NextPage<P, IP> & {
  Layout?: LayoutKeys;
};

export type MyAppProps = AppProps & {
  Component: NextComponentType<NextPageContext, any, any> & {
    Layout: LayoutKeys;
  };
};

export default function App({ Component, pageProps }: AppProps) {
  let l: LayoutKeys = "Main";
  if (Component && Component.displayName) {
    l = Component.displayName as LayoutKeys;
  }
  const Layout = Layouts[l] ?? ((page: string) => page);

  return (
    <>
      <Layout>
        <title>Cidios</title>
        <meta name="description" content="Cidade digital e inteligência de organização social" />
        <GoogleAnalytics />
        <main className={inter.className}>
          <Component {...pageProps} />
        </main>
      </Layout>
    </>
  );
}

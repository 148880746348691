export default function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
          <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <li>
              <a href="https://dash.cidios.com.br/termo-de-uso" className="flex text-sm justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100" aria-label="Termo de uso">
                Termo de uso
              </a>
            </li>
            <li className="ml-4">
              <a href="https://dash.cidios.com.br/politica-de-privacidade" className="flex text-sm justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100" aria-label="Politica de privacidade">
                Politica de privacidade
              </a>
            </li>
          </ul>
          <div className="text-sm text-gray-600 mr-4">&copy; Cidios. Todos os direitos reservados.</div>
        </div>
      </div>
    </footer>
  );
}
